// theme.js
const theme = {
  colors: {
    primary: "#A260FF",
    secondary: "#101012",
    hint: "#7D7C7B",
    textPrimary: "#2B2A29",
    textSecondary: "#52525B",
    white: "#FBFAFF",
    dark: "#1E1E1E",
    lite: "#F1E7FE",
  },
  fontSizes: {
    h1: "4.5rem", 
    h2: "4rem", 
    h3: "2.54rem", 
    p: "1.5rem", 
  },
  
};

export default theme;
