import React from "react";
import Logo from "../assets/tapchatlogo.svg";
import styled, { css, keyframes } from "styled-components";
import theme from "../theme/Theme";
import hash from "../assets/hash.svg";
import at from "../assets/at.svg";
import ai from "../assets/ai.svg";
import cardbg from "../assets/cardbg.svg";

function Landing() {
  const accessProgramLink = 'https://tally.so/r/3ElbMN';

  const handleButtonClick = () => {
    // Open the link in a new tab or window
    window.open(accessProgramLink, '_blank');
  };
  const images = [hash, at, ai];
  return (
    <div>
      <ParentDiv>
        <Logostyle src={Logo} alt="Logo" />
        <CenterParent>
          <Title>
            is poised to revolutionize the way we use messaging apps.
          </Title>
          <SubTitle>
            Prepare for a messaging app revolution with Tapchat it's about to
            change the game.
          </SubTitle>
          <SpecDiv>
            <ImageContainer>
              {images.map((imageSrc, index) => (
                <ImageItem key={index}>
                  <Card>
                    <Image src={imageSrc} alt={`Image ${index + 1}`} />
                  </Card>
                </ImageItem>
              ))}
            </ImageContainer>
            <Waitlisttext>Get on the waiting list</Waitlisttext>
            <Button onClick={handleButtonClick}>Join early access program</Button>
          </SpecDiv>
        </CenterParent>
      </ParentDiv>
      <ItemBg src={cardbg} />
    </div>
  );
}

export default Landing;

const media = {
  mobile: (...args) => css`
    @media (max-width: 600px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (min-width: 601px) and (max-width: 1024px) {
      ${css(...args)};
    }
  `,
};

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

const ParentDiv = styled.div`
  align-items: flex-start; /* Align children to the left */
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  padding-left: 4.5rem;
  padding-right: 4.52rem;
  padding-bottom: 4rem;
  background: linear-gradient(45deg, #ffffff, #f1e7fe);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 10s linear infinite;
  ${media.mobile`
 
  padding-left: 2rem;
  padding-right: 2rem;
  `}
 
`;

const Logostyle = styled.img`
  height: 3.25rem;
  width: 10.125rem;
  margin-top: 3rem;
  ${media.mobile`
  margin-top: 1.5rem;
  `}
`;

const CenterParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; 
`;

const SpecDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-radius: 1rem;
  background-color: ${theme.colors.lite};
  ${media.mobile`
  width:100vw;
  margin-left:1rem;
  margin-right:1rem;
  `}
`;

const Title = styled.h1`
  color: ${theme.colors.textPrimary};
  padding: 0;
  font-family: "Plus Jakarta Sans", Arial, sans-serif;
  font-size: 3.5rem;
  font-style: normal;
  margin: 0;
  text-align: center;
  line-height: 66px;
  font-weight: 900;
  margin-top: 5.625rem;
  width: 52.875rem;
  @media (max-width: 600px) {
    font-size: 1.5rem;
    padding-top: 1rem;
    max-width: 15.5rem;
    margin-top: 2.625rem;
  }
  &::before {
    content: "#Tapchat ";
    color: ${theme.colors.primary};
  }
  ${media.tablet`
        font-size: 2rem;
  padding: 0;
    margin-top: 2.625rem;
     width: 32.875rem;
  `}
`;
const HashTitle = styled.h1`
  color: ${theme.colors.textPrimary};
  padding: 0;
  font-family: "Plus Jakarta Sans", Arial, sans-serif;
  font-size: 3.5rem;
  font-style: normal;
  margin: 0;
  text-align: center;
  line-height: 160%;
  margin-top: 5.625rem;
  width: 52.875rem;
  @media (max-width: 600px) {
    font-size: 1.5rem;
    padding-top: 1rem;
    max-width: 15.5rem;
    margin-top: 2.625rem;
  }
  &::before {
    content: "#";
    color: ${theme.colors.primary};
    display: inline-block;
    transform: scaleX(-1); 
  }
`;

const SubTitle = styled.h1`
  color: ${theme.colors.textSecondary};
  padding: 0;
  font-family: "Plus Jakarta Sans", Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  margin: 0;
  font-weight: 400;
  text-align: center;
  line-height: 160%;
  margin-top: 3rem;
  width: 26.563rem;
  @media (max-width: 600px) {
    font-size: 1rem;
    padding-top: 1rem;
    max-width: 15.5rem;
    margin-top: 1rem;
  }
`;

const Waitlisttext = styled.h1`
  color: ${theme.colors.textPrimary};
  padding: 0;
  font-family: "Plus Jakarta Sans", Arial, sans-serif;
  font-size: 1.5rem;
  font-style: bold;
  margin: 0;
  font-weight: bold;
  text-align: center;
  line-height: 160%;
  margin-top: 3rem;
  width: 26.563rem;
  @media (max-width: 600px) {
    font-size: 1.25rem;
    padding-top: 1rem;
    max-width: 15.5rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  overflow-x: auto;
  grid-gap: 4rem;
  @media (max-width: 600px) {
    flex-direction: column;
    grid-gap: 1.5rem;
  }
`;

const ImageItem = styled.div`
  @media (min-width: 600px) {
    ${"" /* margin-right: 4rem; */}
  }
`;

const Image = styled.img`
  width: 9rem;
  height: auto;
`;

const ItemBg = styled.img`
  width: 100%;
  margin-top: 5.625rem;
  @media (max-width: 600px) {
    width: 100vw;
    margin-top: 2.625rem;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 10px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  background-color: ${theme.colors.primary};
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 2rem;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: black;
    color: white;
  }
  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
const Card = styled.div`
  perspective: 1000px;
  transition: transform 0.9s; 

  &:hover {
    transform: rotateY(360deg); 
  }
`;
